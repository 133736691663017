import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const YumClippedRectsPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{}}>
        <div>
          <h2 className="headline headline-h2 relative py-2">
            Clippings for Rectangles
          </h2>
          <div className="w-1/2 h-80 bg-orange yum-clip-rect-left-leaning"></div>
          <code className="block pb-8">yum-clip-rect-left-leaning</code>
          <br />
          <div className="w-1/2 h-80 bg-magenta yum-clip-rect-right-leaning"></div>
          <code className="block pb-8">yum-clip-rect-right-leaning</code>
          <br />

          <div className="w-1/2 h-80 bg-purple yum-clip-edge-top-right"></div>
          <code className="block pb-8">yum-clip-edge-top-right</code>
          <br />

          <div className="w-1/2 h-80 bg-green yum-clip-edge-top-left"></div>
          <code className="block pb-8">yum-clip-edge-top-left</code>
          <br />

          <div className="w-1/2 h-80 bg-purple yum-clip-cutout-top-right"></div>
          <code className="block pb-8">yum-clip-cutout-top-right</code>
          <br />

          <div className="w-1/2 h-80 bg-green yum-clip-cutout-top-left"></div>
          <code className="block pb-8">yum-clip-cutout-top-left</code>
          <br />
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default YumClippedRectsPage
